.popup-overlay {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Center the popup */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it appears above other content */
    pointer-events: auto; /* Enable pointer events on the overlay */
    opacity: 1; /* Fully visible */
    transition: opacity 0.3s ease; /* Fade transition */
}

.popup-card {
    background: #44475a; /* Match the partner card background */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding inside the popup */
    max-width: 500px; /* Maximum width */
    width: 90%; /* Responsive width */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    position: relative; /* Position relative for the close button */
    pointer-events: auto; /* Enable pointer events on the card */
}

.popup-card__image {
    width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 4px; /* Match card styling */
}

.popup-card__title {
    font-size: 1.5rem; /* Title font size */
    color: #ff3333; /* Title color */
    margin: 10px 0; /* Space around title */
}

.popup-card__description {
    font-size: 1rem; /* Description font size */
    color: #f8f8f2; /* Light text color */
    margin: 10px 0; /* Space around description */
}

/* Add a class for fading out */
.popup-overlay.fade-out {
    opacity: 0; /* Fully transparent */
    pointer-events: none; /* Disable pointer events when fading out */
}

.popup-button-container {
margin-top: 20px; /* Space above the buttons */
display: flex; /* Use flexbox for layout */
justify-content: center; /* Center the buttons */
gap: 10px; /* Space between buttons */
}

.popup-button {
background-color: #CC0033; /* Button background */
color: #FFFFFF; /* Button text color */
border: none; /* Remove default border */
border-radius: 5px; /* Rounded button corners */
padding: 10px 15px; /* Button padding */
cursor: pointer; /* Pointer cursor on hover */
transition: background-color 0.3s; /* Smooth background change */
font-size: 1rem; /* Font size */
}

.popup-button:hover {
background-color: #ff3333; /* Change background on hover */
}

/* Loading Popup Styles */
.loading-popup {
    background: #44475a; /* Same background as the main card */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding inside the loading popup */
    text-align: center; /* Center align text */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
}

/* Spinner styling */
.spinner {
    margin-bottom: 15px; /* Space below the spinner */
}

/* Note styling */
.popup-card__note {
    font-size: 0.9rem; /* Slightly smaller font for the note */
    color: #f8f8f2; /* Light text color */
    margin: 10px 0; /* Space around the note */
}

/* Timer styling */
.popup-card__timer {
    font-size: 1.5rem; /* Larger font for the timer */
    color: #ffcc00; /* Distinct color for visibility */
    margin-top: 10px; /* Space above the timer */
}