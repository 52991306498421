.partner-card {
    background: #44475a; /* Darker background for the card */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Padding for the card */
    margin: 20px; /* Space around the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
    text-align: center; /* Center text */
  }
  

  
  .partner-card__image {
    width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 4px; /* Match card styling */
  }
  
  .partner-card__title {
    font-size: 1.5rem; /* Title font size */
    color: #ff3333; /* Title color */
    margin: 10px 0; /* Space around title */
  }
  
  .partner-card__description {
    font-size: 1rem; /* Description font size */
    color: #f8f8f2; /* Light text color */
    margin: 10px 0; /* Space around description */
  }
  
  .partner-card__button {
    background-color: #CC0033; /* Button background */
    color: #FFFFFF; /* Button text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded button corners */
    padding: 10px 15px; /* Button padding */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background change */
  }
  
  .partner-card__button:hover {
    background-color: #ff3333; /* Change background on hover */
  }