/* Import a Google Font in your index.html or via CSS */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.App {
  text-align: center;
  background-color: #222; /* Dark background */
  color: #FFFFFF; /* Light text color */
  font-family: 'Roboto', sans-serif; /* Clean, modern font */
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(-90deg, #1a1a1a, #630019); /* Darker gradient background for the navigation bar */
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Add shadow for depth */
}

.top-nav__logo img {
  height: 40px; /* Adjust the height of the logo */
}

.top-nav__links a {
  color: #FFFFFF; /* White color for the links */
  text-decoration: none;
  margin: 0 15px;
  font-weight: 500; /* Medium weight for links */
  transition: color 0.3s; /* Smooth color transition */
}

.top-nav__links a:hover {
  color: #ff3333; /* Change color on hover for better visibility */
}

.App-header {
  background-color: #333; /* Darker header background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; /* Base font size for better readability */
  color: #FFFFFF; /* Change text color to white */
  animation: fadeIn 1s ease-in; /* Fade-in animation */
}

.App-header h1 {
  font-family: 'Lato', sans-serif; /* Use a more professional font for the header */
  font-size: 3rem; /* Larger font for the title */
  font-weight: 700; /* Bold for emphasis */
  color: #FFFFFF; /* White color for the title */
  margin-bottom: 10px; /* Space between title and paragraph */
}

.App-header p {
  font-family: 'Roboto', sans-serif; /* Keep the body text in Roboto */
  font-size: 1.25rem; /* Slightly smaller than the title */
  color: #FFFFFF; /* White color for the paragraph */
  margin-bottom: 20px; /* Space below the paragraph */
}

.App-link {
  color: #ff3333; /* Red color for the link */
  font-weight: 700; /* Bold for emphasis */
  font-size: 1.2rem; /* Slightly larger for visibility */
}

.App-footer {
  background: linear-gradient(-90deg, #1a1a1a, #630019); /* Darker gradient background for the footer */
  color: #FFFFFF; /* White color for the text in the footer */
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px 20px;
}

.App-footer a {
  color: #FFFFFF; /* White color for the footer links */
  text-decoration: none;
  margin: 0 15px;
  font-weight: 500; /* Medium weight for footer links */
  transition: color 0.3s; /* Smooth color transition */
}

.App-footer a:hover {
  color: #ff3333; /* Change color on hover for better visibility */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.example-card {
  background: #555; /* Card background color */
  border-radius: 8px; /* Rounded corners */
  padding: 15px;
  margin: 10px 0;
  transition: transform 0.3s, box-shadow 0.3s; /* Animation on hover */
}

.example-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
}

.execute-button {
  background-color: #CC0033; /* Button background */
  color: #FFFFFF; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded button corners */
  padding: 10px 15px; /* Button padding */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background change */
}

.execute-button:hover {
  background-color: #ff3333; /* Change background on hover */
}

/* Catalog Section Styles */
/* Add this to your App.css */

/* General styles for the examples section */
.examples-section {
  padding: 30px;
  max-width: 1200px; /* Center the content */
  margin: 0 auto; /* Center the section */
}

/* Example card styles */
.example-card {
  background-color: #44475a; /* Darker background for each card */
  border-radius: 8px;
  margin: 20px 0; /* Space between cards */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

/* Responsive image */
.responsive-image {
  width: 50%; /* Make the image responsive */
  height: 50%; /* Maintain aspect ratio */
  border-radius: 4px; /* Match card styling */
}

/* Responsive styles */
@media (max-width: 768px) {
  .example-card {
    padding: 15px; /* Adjust padding for mobile */
  }

  h1 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  h2 {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }

  h3 {
    font-size: 1.25rem; /* Smaller font size for mobile */
  }

  p {
    font-size: 1rem; /* Smaller font size for mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Tablet styles */
  h1 {
    font-size: 2.5rem; /* Adjust font size for tablets */
  }

  h2 {
    font-size: 1.75rem; /* Adjust font size for tablets */
  }

  h3 {
    font-size: 1.5rem; /* Adjust font size for tablets */
  }
}

/* Styles for the section header */
.examples-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  border-bottom: 3px solid #6272a4; /* Border for the header */
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

/* Styles for the list of functions */
.examples-section ul {
  list-style-type: none; /* Remove default list style */
  padding-left: 0; /* Remove padding */
  text-align: center;
}

.examples-section li {
  margin: 15px 0; /* Space between list items */
}

.examples-section a {
  color: #ff3333; /* Link color */
  font-size: 18px;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}

.examples-section a:hover {
  color: #ffffff; /* Change color on hover */
  transform: scale(1.05); /* Slight scaling effect */
}

/* Styling for individual function examples */


.example-card:hover {
  transform: translateY(-5px); /* Lift the card up slightly */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

/* Title and description styles for each card */
.example-card h3 {
  font-size: 22px;
  color: #ff3333; /* Highlight the card titles */
  margin-bottom: 10px;
}

.example-card p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #f8f8f2; /* Light text color */
}

/* Code block style */
pre {
  background-color: #1e1e2f; /* Darker background for code */
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
  overflow-x: auto;
}

code {
  color: #8be9fd; /* Light cyan for code text */
}

.go-top {
  position: fixed; /* Fixed position */
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  background-color: #ff3333; /* Background color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 50%; /* Circular shape */
  width: 50px; /* Width of the button */
  height: 50px; /* Height of the button */
  display: flex; /* Flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  transition: background-color 0.3s; /* Smooth background change */
}

.go-top:hover {
  background-color: #cc0033; /* Darker shade on hover */
}

.image-card {
  background: #44475a; /* Darker background for image card */
  border-radius: 8px; /* Rounded corners */
  margin: 10px 0; /* Space between image cards */
  padding: 5px; /* Padding for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.image-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

.image-card img {
  max-width: auto; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Match card styling */
}

.image-cards {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: center; /* Center the cards horizontally */
  gap: 2px; /* Space between cards */
  margin: 2px 0; /* Add margin for spacing */
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.header-title {
  animation: slideInLeft 1.5s ease forwards; /* Slide in from the left */
}

.header-subtitle {
  animation: slideInRight 1.5s ease forwards; /* Slide in from the right */
}

.partner-cards-container {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: center; /* Center the cards horizontally */
  flex-wrap: wrap; /* Allow wrapping to the next line on smaller screens */
  gap: 20px; /* Space between cards */
  margin: 20px 0;
}